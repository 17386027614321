import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Footer.css';
import facebookLogo from "../../images/facebook.png"
import whOptimistLogo from "../../images/WHOptimistLogo.png"


type FooterProps = {
  footerClass?: string;
}

function Footer(props: FooterProps) {


  let footerClass = "Footer banner";
  if (props.footerClass) {
    footerClass += " " + props.footerClass;
  }


  const content =

    <div className={footerClass}>
      <Row>
        <Col lg={{ offset: 3, span: 6 }} className="mx-auto">
          <div>Learn more about Winter Haven Optimist Club</div>
          <div className="mt-2">
            <span title="Winter Haven Optimist Club Facebook page" className="mx-2">
              <a href="https://www.facebook.com/WHOptimistClub" target="_blank" rel="noreferrer"><img
                className="socials" src={facebookLogo} alt="Facebook icon" /></a>
            </span>
            <span title="Winter Haven Optimist Club web site" className="mx-2">
              <a href="https://www.winterhavenoptimistclub.org/" target="_blank" rel="noreferrer"><img className="socials"
                src={whOptimistLogo} alt="Winter Haven Optimist Club logo" /></a>
            </span>
          </div>
        </Col>
      </Row>
    </div>
    ;

  return content;
}

export default Footer;

