import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import '../pagewrapper/PageWrapper.css';
import '../../index.css';

type PageWrapperProps = {
  contentClass?: string;
  headerClass?: string;
  footerClass?: string;
  children?: React.ReactNode;
}

function PageWrapper(props: PageWrapperProps) {


  let contentClass = "content";
  if (props.contentClass) {
    contentClass += " " + props.contentClass;
  }

  const content =
    <Container fluid className="PageWrapper">
      <Row>
        <Col>
          <Header headerClass={props.headerClass} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={contentClass}>
            {props.children}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer footerClass={props.footerClass} />
        </Col>
      </Row>
    </Container>
    ;

  return content;
}

export default PageWrapper;

