import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Course from './Course';
import Sponsors from './Sponsors';
import Awards from './Awards';
import Registration from './Registration';
import ReactGA from 'react-ga4';
import MailinRegistration from './MailinRegistration';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Is the hostname for a test site? */
const hostname = window.location.hostname.toLocaleLowerCase();
// if any of these strings are part of the hostname, it is test mode.
const testHostList = ["localhost", "preview"];
let isTestSite = false;
for (const m of testHostList) {
  if (hostname.indexOf(m) > -1) {
    isTestSite = true;
    break;
  }
}

if (isTestSite) {
  // Use debug properties.
  ReactGA.initialize("G-QVZQXERDJ1",
    {
      gtagOptions: { 'debug_mode': true },
      gaOptions: {
        testMode: true,
      }
    }
  );
} else {
  // This is live site, so no debug analytics mode.
  ReactGA.initialize("G-QVZQXERDJ1");
}

export const pageTitleBase = "Santa's Run Fest";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/course" element={<Course />}></Route>
        <Route path="/sponsors" element={<Sponsors />}></Route>
        <Route path="/awards" element={<Awards />}></Route>
        <Route path="/registration" element={<Registration />}></Route>
        <Route path="/mailin-registration" element={<MailinRegistration />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
