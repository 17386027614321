import React, { useEffect } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { pageTitleBase } from '.';
import PageWrapper from './components/pagewrapper/PageWrapper';
import { Row, Col } from 'react-bootstrap';

function Home() {

  useEffect(() => {
    document.title = pageTitleBase + ` - Home`;
  });

  const content =

    <PageWrapper headerClass="bannerhome" footerClass="bannerhome" contentClass="Home mainpage">
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <h1>Santa's Run Fest</h1>
          <h2>A Run for Our Kids</h2>
          <h6>presented by</h6>
          <h3>Winter Haven Optimist Club</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 4, offset: 4 }} className="textbox">
          <h2>Saturday Dec 17, 2022</h2>
          <div className="racetimetable">
            <Row>
              <Col xs={6} className="racetype">Half Marathon</Col>
              <Col xs={6} className="racetime">7:00 am</Col>
            </Row>
            <Row>
              <Col xs={6} className="racetype">10k</Col>
              <Col xs={6} className="racetime">7:00 am</Col>
            </Row>
            <Row>
              <Col xs={6} className="racetype">5k</Col>
              <Col xs={6} className="racetime">7:15 am</Col>
            </Row>
            <Row>
              <Col xs={6} className="racetype">Kids' Races</Col>
              <Col xs={6} className="racetime">9:00 am</Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <h6 className="button-text mainbutton"><Link className='button button-accent' to="registration">Registration Information</Link></h6>
        </Col>
      </Row>

    </PageWrapper>
    ;

  return content;
}

export default Home;

