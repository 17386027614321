import React from 'react';
import './AwardInfoBlock.css';


type AwardInfoBlockProps = {
    iconImageSrc: string;
    iconImageAltText: string;
    title: string;
    children: React.ReactNode;
}

function AwardInfoBlock(props: AwardInfoBlockProps) {

    const content =

        <div className="AwardInfoBlock mt-5">
            <div>
                <img className="icon" src={props.iconImageSrc} alt={props.iconImageAltText} />
            </div>
            <h2 className="mt-2">{props.title}</h2>
            <div>
                {props.children}
            </div>
        </div>
        ;

    return content;
}

export default AwardInfoBlock;
