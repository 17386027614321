import React from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import './Menu.css';

function Menu() {
  return (

    <div className="Menu">
      <div className="navigation">
        <Row>
          <Col xl={1} md={2} className="mx-auto"><Link to="/">Home</Link></Col>
          <Col xl={1} md={2} className="mx-auto"><Link to="/course">Course</Link></Col>
          <Col xl={1} md={2} className="mx-auto"><Link to="/sponsors">Sponsors</Link></Col>
          <Col xl={1} md={2} className="mx-auto"><Link to="/awards">Awards</Link></Col>
          <Col xl={1} md={2} className="mx-auto"><Link to="/registration">Registration</Link></Col>
        </Row>
      </div>
    </div>
  );
}

export default Menu;

