import React, { useEffect } from 'react';
import './Sponsors.css';
import { pageTitleBase } from '.';
import PageWrapper from './components/pagewrapper/PageWrapper';
import Sponsor, { SponsorshipLevel } from './components/sponsors/Sponsor';
import { Col, Row } from 'react-bootstrap';


function Sponsors() {

    useEffect(() => {
        document.title = pageTitleBase + ` - Sponsors`;
    });

    return (

        <PageWrapper>
            <div className="Sponsors">
                <h1>Sponsors</h1>
                <div>
                    <h2>Become a 2022 Race Sponsor!</h2>
                    <p>More information coming soon</p>
                </div>

                <div>
                    <h2>2021 Race Sponsors</h2>

                    <div className="sponsor-level olympic-sponsor-level">
                        <h3>Olympic Sponsors: $1,500 and up</h3>
                        {/* <Sponsor sponsorName="" sponsorLink="" logoSrc="" logoAltText="" /> */}
                        <Row>
                            <Col lg={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Olympic} sponsorName="Eye Specialists of Mid-Florida, P.A." sponsorLink="https://www.eyesfl.com" logoSrc="images/sponsor-logos/EyeSpecialists.png" logoAltText="Eye Specialists of Mid-Florida logo" /></Col>
                            <Col lg={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Olympic} sponsorName="Paradise Oaks RV Resort" sponsorLink="https://www.paradiseoaksrv.com/" logoSrc="images/sponsor-logos/ParadiseOaks.jpg" logoAltText="Paradise Oaks RV Resort logo" /></Col>
                        </Row>
                    </div>

                    <div className="sponsor-level elite-sponsor-level">
                        <h3>Elite Sponsors: $1,000 and up</h3>
                        <Row>
                            <Col lg={3} md={4} sm={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Elite} sponsorName="Bond Clinic, P.A." sponsorLink="https://www.bondclinic.com/" logoSrc="images/sponsor-logos/BondClinicLogo.png" logoAltText="Bond Clinic logo" /></Col>
                            <Col lg={3} md={4} sm={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Elite} sponsorName="Church of the Redeemer" sponsorLink="https://www.redeemerwinterhaven.org/" logoSrc="images/sponsor-logos/Redeemer_Church.png" logoAltText="Church of the Redeemer logo" /></Col>
                        </Row>
                    </div>

                    <div className="sponsor-level marathon-sponsor-level">
                        <h3>Marathon Sponsors: $500 and up</h3>
                        <Row>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Gessler Clinic, P.A." sponsorLink="https://www.gesslerclinic.com/" logoSrc="images/sponsor-logos/GesslerClinic.jpg" logoAltText="Gessler Clinic logo" /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Pinnacle Sales Group" sponsorLink="https://pinnaclesalesgroup.com/" logoSrc="images/sponsor-logos/Pinnacle.png" logoAltText="Pinnacle Sales Group logo" /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Winter Haven Hospital/Baycare" sponsorLink="https://baycare.org/hospitals/winter-haven-hospital/patients-and-visitors" logoSrc="images/sponsor-logos/WinterHavenHospital.jpeg" logoAltText="Winter Haven Hospital logo" /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Little Wolfe&apos;s Pet Resort" sponsorLink="https://www.littlewolfes.com/" logoSrc="images/sponsor-logos/Little Wolf.png" logoAltText="Little Wolfe&apos;s Pet Resort logo" /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Peterson &amp; Myers, PA" sponsorLink="https://www.petersonmyers.com/" logoSrc="images/sponsor-logos/Peterson & Myers.png" logoAltText="Peterson &amp; Myers logo" /></Col>
                            <Col xl={2} md={3} sm={4} xs={6} className="mx-auto"><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="Advanced Orthopedic Surgery" sponsorLink="" logoSrc="images/sponsor-logos/AdvancedOrthopedic.jpg" logoAltText="Advanced Orthopedic Surgery logo" /></Col>
                            {/* <Col><Sponsor sponsorshipLevel={SponsorshipLevel.Marathon} sponsorName="John Pilkey &amp; Associates, Inc." sponsorLink="" logoSrc="images/sponsor-logos/PilkeyAndAssociates.png" logoAltText="John Pilkey &amp; Associates" /></Col> */}
                        </Row>
                    </div>

                    <div className="sponsor-level sprinter-sponsor-level">
                        <h3>Sprinter Sponsors: $250 and up</h3>
                        <Row>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Anytime Fitness" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Bill and Donna Jackson" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Beef &apos;O&apos; Brady's Winter Haven" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Brooks Law Group" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Central Buick GMC Inc." /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Chevrolet Center, Inc" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Citizen&apos;s Bank &amp; Trust" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Dr. Steven McNutt C, DDS, PA" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Dwight H. Pate DMD, PA" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Famous Tate" sponsorLink="https://www.famoustate.com/" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Florida Dermatology and Skin Cancer Centers" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Floyd, Sammons &amp; Spanjers, P.A." /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Heartland Church" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Howell and Thornhill, P.A." /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Ingram Financial Group" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="J n&apos; C Sewing" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="John Pilkey and Associates" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Karen Bryan State Farm" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Kevin L. Henne O.D." /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Lakeland Optimist Club" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Lanier Groves, LLC" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Mannix Pools and Grills" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Moon Family Dental Care" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Mr. John Crow" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Nicholson Tax Group" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Optical Outlets" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Palo Dental" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Polk State College Foundation" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Robinson&apos;s Auto Paint and Body" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Red Hoagland Hyundai" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="SouthState Bank (Missy Joyce)" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="State Farm Agent Larry Walker" /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Stephen F. Baker P.A." /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Victor Smith Law Group P.A." /></Col>
                            <Col xxl={3} xl={4} lg={6}><Sponsor sponsorshipLevel={SponsorshipLevel.Sprinter} sponsorName="Winter Haven Dermatology" /></Col>
                        </Row>
                    </div>

                    <Row className="mt-5">
                        <Col xl={4} lg={6} md={8} className="mx-auto sponsors-thank-you textbox">
                            <img className="optimistlogo sponsor-optimistlogo" src="images/OptimistLogo.jpg" alt="Winter Haven Optimist Club logo" />
                            <div className="mt-5">
                                <p>Many heart-felt thanks to our wonderful sponsors. Because of you, we are able to support more youth throughout our community. Santa&apos;s Run Fest is only one of many events that the Optimist Club of Winter Haven performs. Again............ THANK YOU!!!</p>
                                <p>If you or your company are interested in sponsoring, please contact <a href="mailto:renawood@aol.com">renawood@aol.com</a>.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


        </PageWrapper>
    );
}

export default Sponsors;

