import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { pageTitleBase } from '.';
import './Awards.css';
import AwardInfoBlock from './components/awards/AwardInfoBlock';
import PageWrapper from './components/pagewrapper/PageWrapper';

function Awards() {

    useEffect(() => {
        document.title = pageTitleBase + ` - Awards`;
    });

    return (

        <PageWrapper>
            <div className="Awards">

                <h1>Awards</h1>

                <Row className="mt-2">
                    <Col lg={4}>
                        <AwardInfoBlock title="Awards for Lowest Times" iconImageSrc="images/icon-awards.png" iconImageAltText="Line drawing of three awards podiums.">
                            <p>Award medals for Half Marathon, 10k, and 5k for...
                                <ul>
                                    <li>Overall race winners (male and female)</li>
                                    <li>Top 3 male and top 3 female runners in each age division</li>
                                </ul>
                            </p>
                            <p>Kids receive trophies for 1st and 2nd place for both boys and girls in each youth division.</p>
                        </AwardInfoBlock>
                    </Col>
                    <Col lg={4}>
                        <AwardInfoBlock title="Finisher Medals &amp; Lanyards" iconImageSrc="images/icon-medal.png" iconImageAltText="Line drawing of an award medal.">
                            <p>Finisher medals for...
                                <ul>
                                    <li>All runners who complete the race in Half Marathon, 10k, and 5k</li>
                                    <li>All kids will receive finisher medals</li>
                                </ul>
                            </p>
                        </AwardInfoBlock>
                    </Col>
                    <Col lg={4}>
                        <AwardInfoBlock title="Tech Shirts" iconImageSrc="images/icon-shirt.png" iconImageAltText="Line drawing of a shirt.">
                            <p>Tech Shirts for 5k, 10k & Half Marathon runners registered by
                                December 1. All kids receive cotton shirts with logos.</p>
                        </AwardInfoBlock>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4}>
                        <img className="photo" src="images/awards1.jpg" alt="People milling around an awards table waiting for race awards to be distributed." />
                    </Col>
                    <Col lg={4}>
                        <img className="photo" src="images/awards2.jpg" alt="More people waiting for award medals to be presented." />
                    </Col>
                    <Col lg={4}>
                        <img className="photo" src="images/awards3.jpg" alt="Runners awaiting the award presentation." />
                    </Col>
                </Row>



            </div>
        </PageWrapper>
    );
}

export default Awards;

