import React, { useEffect } from 'react';
import './Registration.css';
import { pageTitleBase } from '.';
import PageWrapper from './components/pagewrapper/PageWrapper';
import { Col, Row } from 'react-bootstrap';


function Registration() {

    useEffect(() => {
        document.title = pageTitleBase + ` - Registration`;
    });

    const currDate = Date.now();

    let regPeriod1, regPeriod2, regPeriod3, regPeriod4 = false;
    let showRegButton = true;
    if (currDate < Date.parse("2022-09-04T00:00:00-0400")) {
        regPeriod1 = true;
    } else if (currDate < Date.parse("2022-10-31T00:00:00-0400")) {
        regPeriod2 = true;
    } else if (currDate < Date.parse("2022-12-16T20:00:00-0500")) {
        regPeriod3 = true;
    } else if (currDate < Date.parse("2022-12-17T07:15:00-0500")) {
        regPeriod4 = true;
    } else {
        showRegButton = false;
    }

    const content =
        <PageWrapper>
            <div className="Registration">

                <h1>Registration</h1>

                <Row>
                    <Col lg={{ offset: 4, span: 4 }} >

                        {showRegButton ?
                            <div>
                                <h6 className="button-text mainbutton"><a className="button button-accent" target="_blank" rel="noreferrer" href="https://runsignup.com/Race/FL/WinterHaven/SantasRunFest">Register Online</a></h6>
                            </div>
                            :
                            <div className="textbox textbox-highlight">
                                <h5>Registration closed!</h5>
                            </div>
                        }

                        <p>Online registration from July&nbsp;1,&nbsp;2022&nbsp;at&nbsp;12:00&nbsp;am to December&nbsp;16,&nbsp;2022&nbsp;at&nbsp;8:00&nbsp;pm</p>
                        <p>In-person registration only on race day</p>
                        <p className="button-caption margin-bottom">Any questions? Please email <a href="mailto:custer4music@yahoo.com">custer4music@yahoo.com</a></p>
                    </Col>
                </Row>

                {/* <!-- <div >
                    <div>
                        <h6 className="button-text mainbutton"><a className="button button-accent" href="registration/mailin.html">Mail-In Registration</a></h6>
                        <h6 className="button-text mainbutton"><a className="button button-accent" href="#">Mail-In
                                Registration</a></h6>
                        <p>Coming soon!</p>
                        <p className="button-caption">Postmarked by Dec. 4th</p> 
                    </div>
                </div> --> */}

                <h2 className="mt-5">Registration Fees</h2>

                <Row>
                    <Col md={6} xl={3} className="mt-3">
                        <div className={regPeriod1 ? "textbox textbox-highlight" : "textbox"}>
                            <h3 className="registration-category">On or Before Sept 3</h3>
                            <div>$45 for Half Marathon</div>
                            <div>$25 for 10K</div>
                            <div>$25 for 5K</div>
                        </div>
                    </Col>
                    <Col md={6} xl={3} className="mt-3">
                        <div className={regPeriod2 ? "textbox textbox-highlight" : "textbox"}>
                            <h3 className="registration-category">From Sept 4 through Oct 30
                            </h3>
                            <div>$50 for Half Marathon</div>
                            <div>$30 for 10K</div>
                            <div>$30 for 5K</div>
                        </div>
                    </Col>
                    <Col md={6} xl={3} className="mt-3">
                        <div className={regPeriod3 ? "textbox textbox-highlight" : "textbox"}>
                            <h3 className="registration-category">From Oct 31 through Dec 16
                            </h3>
                            <div>$55 for Half Marathon</div>
                            <div>$35 for 10K</div>
                            <div>$35 for 5K</div>
                        </div>
                    </Col>
                    <Col md={6} xl={3} className="mt-3">
                        <div className={regPeriod4 ? "textbox textbox-highlight" : "textbox"}>
                            <h3 className="registration-category">Day of Race (Dec 17)</h3>
                            <div>$60 for Half Marathon</div>
                            <div>$40 for 10K</div>
                            <div>$40 for 5K</div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} xl={3} className="mt-3 mx-auto">
                        <div className="textbox">
                            <h3>Students</h3>
                            <div>Regular price for Half Marathon</div>
                            <div>$25 for 10K</div>
                            <div>$20 for 5K</div>
                        </div>
                    </Col>
                    <Col md={6} xl={3} className="mt-3 mx-auto">
                        <div className="textbox">
                            <h3>Kids Races (Under 12)</h3>
                            <div>$5 for all kids</div>
                            <div>Race distance varies with age group</div>
                            <br />
                        </div>
                    </Col>
                </Row>


            </div>
        </PageWrapper >
        ;

    return content;
}

export default Registration;

