import React, { useEffect } from 'react';
import './MailinRegistration.css';
import Menu from './components/menu/Menu';
import { pageTitleBase } from '.';

function MailinRegistration() {

    useEffect(() => {
        document.title = pageTitleBase + ` - Registration`;
    });

    return (
        <div className="MailinRegistration">

            <div className="banner">
                <Menu />
            </div>

            <div className="registration">
                <h5>Printable Mail-In Registration Form Available At:</h5>
                <p>(Not formatted for smart phones)</p>
                <h6 className="mainbutton button-text"><a className="button button-accent" href="RegistrationForm.pdf">Printable
                    Form</a></h6>
                <h5>Make Checks Payable to:</h5>
                <p>Winter Haven Optimist Club</p>
                <h5>Mailing Address:</h5>
                <p>Winter Haven Optimist Club</p>
                <p>2841 S. Lake Deer Dr.</p>
                <p>Winter Haven, Florida 33880</p>
                <p className="red border-under70">Must be postmarked by December 5th</p>
            </div>


        </div>
    );
}

export default MailinRegistration;

