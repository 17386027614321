import React, { useEffect } from 'react';
import './Course.css';
import { pageTitleBase } from '.';
import PageWrapper from './components/pagewrapper/PageWrapper';
import { Col, Row } from 'react-bootstrap';


function Course() {

  useEffect(() => {
    document.title = pageTitleBase + ` - Course`;
  });

  return (

    <PageWrapper>
      <Row className="Course">
        <h1>Course</h1>
        <Col lg={6}>

          <h3>Updated course map coming soon...</h3>
          {/* 
        <div className="video"><iframe src="https://www.youtube.com/embed/DUYvPRXJdmw" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe></div>
        <div className="address">
          <p>Start/finish near Chamber Building in Central Park</p>
          <p>401 Avenue B NW</p>
          <p>Winter Haven, FL 33881</p>
        </div>
        <a href="course/CourseMap.jpg"><img className="thumbnail" src="course/CourseMap.jpg"></a>
        */}

        </Col>
        <Col lg={6}>
          <h5>About Winter Haven</h5>
          <p className="margin-bottom-small"> Winter Haven is a beautiful city of approximately 35,000 friendly folks. As the
            Chamber of Commerce mentions, "To some we're the perfect haven from the harsh winter months up North, and to
            others we are the opportunity to be involved in a town
            with over 55 lakes to enjoy with fantastic sunrises and sunsets over the water" ...AND an absolutely supurb
            terrain for running. </p>

          <p className="margin-bottom-small">This particular course includes gentle rises, enough to cause pressure for the
            advanced competitors but not so much to frustrate the average person. This trek will pass by three pristine
            lakes and many lovely holiday decorated homes. It will be
            well marked with security and volunteers all along the route. </p>

          <p className="margin-bottom">Newbies are especially encouraged to join the fastest growing athletic activity in the
            world. Running encourages increased physical fitness, effective weight control, greater patience, and an
            overall sense of well-being. Besides that, runners
            seem to be among the most happy, up-beat, smiling, smart, fun-loving, good-looking, physically-fit humans on
            the planet. At the finish, everyone is exhausted, sweaty, smelly, somewhat dirty, frequently nauseous,
            but... SMILING!</p>
        </Col>

      </Row>
    </PageWrapper>
  );
}

export default Course;

